// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyDRzVs9AS04fCm73H4gTWJ7QHjs0BzGcF4",
    authDomain: "atila-prod.firebaseapp.com",
    databaseURL: "https://atila-prod.firebaseio.com",
    projectId: "atila-prod",
    storageBucket: "atila-prod.appspot.com",
    messagingSenderId: "197634361177",
    appId: "1:197634361177:web:1f0788af961daa0cea2e48",
    measurementId: "G-NP11XV66XS"
};